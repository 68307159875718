<template>
  <div>
    <!-- Right Sidebar -->
    <div v-click-outside="config" class="right-bar">
      <div class="rightbar-title">
        <h5 class="m-0">{{ $t('route.timeTracking') }}</h5>
        <a href="javascript:void(0);" @click="hide">
          <i class="ri-close-line noti-icon"></i>
        </a>
      </div>

      <simplebar class="h-100 rightbar-content">
        <div class="p-3">
          <div class="card" v-for="item in listView.list" :key="item.id" :style="{ 'background-color': 'papayawhip' }">
            <a href="javascript:void(0);" @click="editTimeTrackingItem(item)"> {{ getObjectPresentation(item) }}</a>
            <div class="card-text">
              <div class="row">
                <div class="col-7">
                  <div class="row">
                    <b> {{ getObjectReference(item) }}</b>
                  </div>
                  <div class="row"> {{ $t('table.begin') }}: {{ getDatePresentation(item.startDate) }} </div>
                  <div class="row">
                    {{ item.comment }}
                  </div>
                </div>
                <div class="col">
                  <div class="row" :style="{ 'text-align': 'center' }">
                    <h1>{{ convertTime(item.elapsedTime) }}</h1>
                  </div>
                  <div class="row">
                    <!-- <b-button variant="secondary" class="ml-2" @click="stopTimer(item)">Zatrzymaj</b-button> -->
                    <button class="btn btn-primary ml-2" @click="stopTimer(item)">{{ $t('commands.close') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end padding-->
      </simplebar>
    </div>

    <div class="rightbar-overlay"> </div>
    <!-- /Right-bar -->
  </div>
</template>

<script>
import simplebar from 'simplebar-vue'
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  components: { simplebar },

  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ['click'],
      },
    }
  },

  mounted() {
    this.startTimers()
  },

  watch: {
    'listView.list': {
      handler() {
        this.startTimers()
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      listView: 'objectsTimeTracking/listView',
    }),
  },
  methods: {
    ...mapMutations({
      updateElapsedTime: `objectsTimeTracking/updateElapsedTime`,
      addObjectView: 'objectsTimeTracking/addObjectView',
      setListViewProperty: 'objectsTimeTracking/setListViewProperty',
    }),

    hide() {
      document.body.classList.toggle('right-bar-enabled')
    },
    handleRightBarClick(e, el) {
      document.body.classList.remove('right-bar-enabled')
    },
    middleware(event, el) {
      return !event.target.classList?.contains('toggle-right')
    },

    async updateTimeTrackingObjects() {
      await this.$store.dispatch('objectsTimeTracking/getCurrentObjects')
    },

    getDatePresentation(currentDate) {
      return moment(currentDate).format('DD.MM.YYYY HH:mm:ss')
    },

    async editTimeTrackingItem(trackingObject) {
      if (trackingObject.objectType === 'interaction') {
        await this.editInteraction(trackingObject.objectId)
      }
    },

    getObjectPresentation(trackingObject) {
      if (trackingObject.objectType === 'interaction') {
        return 'Interakcja ' + trackingObject.objectData.numberStr
      } else {
        return 'undefined'
      }
    },

    getObjectReference(trackingObject) {
      if (trackingObject.objectType === 'interaction') {
        return trackingObject.objectData.reference
      } else {
        return 'undefined'
      }
    },

    // calculateTimeDiff(startDateParam) {
    //   let start = moment(startDateParam).format('HH:mm:ss')
    //   let end = moment(new Date()).format('HH:mm:ss')
    //   start = start.split(':')
    //   end = end.split(':')
    //   const startDate = new Date(0, 0, 0, start[0], start[1], 0)
    //   const endDate = new Date(0, 0, 0, end[0], end[1], 0)
    //   let diff = endDate.getTime() - startDate.getTime()
    //   let hours = Math.floor(diff / 1000 / 60 / 60)

    //   diff -= hours * 1000 * 60 * 60
    //   const minutes = Math.floor(diff / 1000 / 60)
    //   const seconds = Math.floor(diff / 1000 / 60 / 60)

    //   if (hours < 0) hours = hours + 24

    //   return (hours <= 9 ? '0' : '') + hours + ':' + (minutes <= 9 ? '0' : '') + minutes
    // },

    // async updateTimeDiffs() {
    //   const currentList = _.cloneDeep(this.listView.list)

    //   for (const row of currentList) {
    //     row.timeDiff = this.calculateTimeDiff(row.startDate)
    //   }

    //   await this.$store.dispatch('objectsTimeTracking/updateObjectList', { params: { list: currentList } })
    // },

    startTimers() {
      this.listView.list.forEach((item) => {
        if (!item.startTime) {
          item.startTime = performance.now()
          this.updateTimer(item)
        }
      })
    },

    updateTimer(item) {
      const endTime = new Date()
      const startTime = new Date(item.startDate)

      const trackSecTime = Math.ceil((endTime.getTime() - startTime.getTime()) / 1000)

      const update = () => {
        const currentTime = performance.now()
        const elapsedSeconds = Math.floor((currentTime - item.startTime) / 1000) + trackSecTime
        this.updateElapsedTime({ itemId: item.id, elapsedTime: elapsedSeconds })
        requestAnimationFrame(update)
      }

      update()
    },

    convertTime(time) {
      if (time < 60) {
        return '00:' + (time < 10 ? '0' + time : time) + ' s.'
      } else if (time < 3600) {
        const minutes = Math.floor(time / 60)
        const secondsAfterMinutes = time - Math.floor(time / 60) * 60

        return (minutes < 10 ? '0' + minutes : minutes) + ':' + (secondsAfterMinutes < 10 ? '0' + secondsAfterMinutes : secondsAfterMinutes) + ' min.'
      } else {
        time = Math.floor(time / 60)

        const hours = Math.floor(time / 60)
        const minutesAfterHours = time - Math.floor(time / 60) * 60

        return (hours < 10 ? '0' + hours : hours) + ':' + (minutesAfterHours < 10 ? '0' + minutesAfterHours : minutesAfterHours) + ' god.'
      }
    },

    async stopTimer(trackingObject) {
      const response = await this.$store.dispatch('objectsTimeTracking/findByPk', {
        params: {
          id: trackingObject.id,
        },
      })

      // const endDate = new Date(new Date())
      // const startDate = new Date(response.data.startDate)

      const params = {
        id: trackingObject.id,
        updatedAt: trackingObject.updatedAt,
        // endDate: endDate,
        // trackTime: Math.ceil((endDate.getTime() - startDate.getTime()) / 1000)
      }

      await this.$store.dispatch('objectsTimeTracking/update', params)
      await this.updateTimeTrackingObjects()
    },

    async editInteraction(objectId) {
      const response = await this.$store.dispatch('interactions/findByPk', {
        params: {
          id: objectId,
        },
      })
      if (response.status === 200) {
        this.$router.push({ name: 'interaction-detail', params: { id: objectId } })
      }
    },
  },
}
</script>

<style lang="scss">
.my-custom-class {
  background-color: red;
}
</style>
