<template>
  <div class="wrapper">
    <!-- <b-modal v-model="instalModules" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc title="Zaczekaj"> Trwa instalacja modulów </b-modal> -->
    <side-bar v-if="showNavigation" />
    <div class="content-page">
      <div class="content">
        <div v-if="showNavigation" class="navbar-custom">
          <topbar :user="user" />
        </div>
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <footer-vue />
    </div>
    <right-sidebar />
  </div>
</template>

<script>
import Topbar from '@/components/topbar'
import SideBar from '@/components/side-bar'
import RightSidebar from '@/components/right-sidebar'
import FooterVue from '@/components/footer'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MainLayout',

  components: { Topbar, SideBar, RightSidebar, FooterVue },

  data() {
    return {
      user: this.$store ? this.$store.state.auth.currentUser : {} || {},
      elem: document.documentElement,
    }
  },

  computed: {
    ...mapGetters({
      desktopMode: 'app/desktopMode',
      currentUser: 'auth/currentUser',
    }),

    instalModules() {
      return this.$store.state.app.instalModules
    },

    showNavigation() {
      if (this.currentUser?.fullRights === true) {
        return true
      }

      return this.desktopMode !== true
    },
  },

  watch: {
    desktopMode() {
      this.updateDesktopMode()
    },

    currentUser: {
      deep: true,
      handler() {
        this.updateDesktopMode()
      },
    },
  },

  created() {
    this.startSessionRefresh()
  },

  mounted() {
    this.updateDesktopMode()
  },

  destroyed() {
    clearInterval(this.refreshSessionInterval)
  },

  methods: {
    ...mapActions({
      refreshSession: 'auth/validate',
    }),

    updateDesktopMode() {
      if (this.desktopMode === true && this.currentUser?.fullRights !== true) {
        document.body.classList.add('desktop-mode')
      } else {
        document.body.classList.remove('desktop-mode')
      }
    },
    startSessionRefresh() {
      this.refreshSessionInterval = setInterval(() => {
        this.refreshSession().then(async (validUser) => {
          if (!validUser) {
            this.$router.push('/login')
          }
        })
      }, 300000)
    },
  },
}
</script>

<style scoped>
.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}
</style>