import axios from 'axios'
import _ from 'lodash'

const initState = {
  expressionVariables: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
  setExpressionVariables(state: any, expressionVariables: any) {
    state.expressionVariables = expressionVariables
  },
  resetState(state: any) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const actions = {
  async getProductParameters({ commit }: any, payload: any) {
    return axios
      .post(`/product_parameter`, payload)
      .then((response) => {
        const parameters = response.data
        return parameters
      })
      .catch((error) => {
        console.error(error)
        return []
      })
  },

  async getProductParamsSettings({ commit }: any, payload: any) {
    return axios
      .get(`/product_param_settings`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
  },

  async getParameterValues({ commit }: any, payload: any) {
    return axios
      .post(`/parameter_value`, payload)
      .then((response) => {
        const parameterValues = response.data
        return parameterValues
      })
      .catch((error) => {
        console.error(error)
        return []
      })
  },

  async getSelectedValues({ commit }: any, payload: any) {
    return axios
      .post(`/selected_value`, payload)
      .then((response) => {
        const parameterValues = response.data
        return parameterValues
      })
      .catch((error) => {
        console.error(error)
        return []
      })
  },

  async getNextParameterValues({ commit }: any, payload: any) {
    return axios
      .post(`/next_param_value`, payload)
      .then((response) => {
        const parameterValues = response.data
        return parameterValues
      })
      .catch((error) => {
        console.error(error)
        return []
      })
  },

  async getExpressionVariables({ commit }: any, payload: any) {
    return axios
      .post(`/expression_variables`, payload)
      .then((response) => {
        const expressionVariables = response.data
        commit('setExpressionVariables', expressionVariables)
        return expressionVariables
      })
      .catch((error) => {
        console.error(error)
        return []
      })
  },

  resetState({ commit }: any) {
    commit('resetState')
  },
}

export const getters = {
  expressionVariables(state: any) {
    return state.expressionVariables
  },
}
