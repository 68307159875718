import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'

const initState = {
  currentView: {
    tablePlumb1: [],
    tablePlumb2: [],
    total1: 0,
    total2: 0,
    page1: 1,
    page2: 1,
    filters: {},
    sort1: { sortBy: null, sortDesc: false },
    sort2: { sortBy: null, sortDesc: false },
    userId: null,
    netto1: 0,
    netto2: 0,
    weighingStationId: null,
    weighingStation: null,
    scale1Id: null,
    scale1: null,
    scale2Id: null,
    scale2: null,
    currentDisposition1: null,
    currentDisposition2: null,
    dispositionFilter: {},
    dispositionFilter1: {},
    dispositionFilter2: {},
    automaticWeightingEnd: false,
    beginWeighting1: null,
    beginWeighting2: null,
    endWeighting1: null,
    endWeighting2: null,
    startWeightings1: false,
    startWeightings2: false,
    isStarted1: false,
    isStarted2: false,
    isStartWrite1: false,
    isStartWrite2: false,
    state1: { id: 'notWeighting', name: 'Oczekiwanie na ważenie' },
    state2: { id: 'notWeighting', name: 'Oczekiwanie na ważenie' },
    startStopRielaBunkerWhater: null,
    isTimer1Worked: false,
    isTimer2Worked: false,
    startEnumerator1: -1,
    startEnumerator2: -1,
    nettoEnumerator1: 0,
    nettoEnumerator2: 0,
  },
  objectViews: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
  setListViewProperty(state, payload) {
    Object.keys(payload).forEach((key) => {
      state.currentView[key] = payload[key]
    })
  },

  setFilters(state, filter) {
    Object.keys(filter).forEach((key) => {
      state.listView.filters[key] = filter[key]
    })
  },

  setSort(state, sort) {
    Object.keys(sort).forEach((key) => {
      state.listView.sort[key] = sort[key]
    })
  },

  addObjectView(state, view) {
    const existView = state.objectViews.find((el) => el.viewId === view.viewId)

    if (existView === undefined) {
      state.objectViews.push(view)
    }
  },

  setObjectViewProperty(state, payload) {
    const index = state.objectViews.findIndex((el) => el.viewId === payload.viewId)
    if (index > -1) {
      state.objectViews[index][payload.property] = payload.value
    }
  },

  setObjectProperty(state, payload) {
    const index = state.objectViews.findIndex((el) => el.viewId === payload.viewId)
    if (index > -1) {
      state.objectViews[index].object[payload.property] = payload.value
    }
  },

  delObjectView(state, viewId) {
    for (const [i, v] of state.objectViews.entries()) {
      if (v.viewId === viewId) {
        state.objectViews.splice(i, 1)
        break
      }
    }
  },

  // setTablePlumb1(state, tableRows) {
  //   state.currentView.tablePlumb1 = tableRows
  // },

  // setTablePlumb2(state, tableRows) {
  //   state.currentView.tablePlumb2 = tableRows
  // },

  setCurrentView(state, currentView) {
    state.currentView = currentView
  },
  resetState(state) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const actions = {
  async findAll({ commit }, payload) {
    const list = []
    return axios
      .get(`/plumb_lines`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async fillPlumbLines({ commit }, payload) {
    let params = {}

    if (payload.disposition1Id) {
      let tablePlumb1 = []
      let total1 = 0
      let page1 = 1
      let startEnumerator1 = -1
      let beginWeighting1 = null

      params = {
        params: {
          filter: {
            dispositionId: payload.disposition1Id,
            endWeighting: null,
          },
          noCommit: true,
        },
      }
      const response = await actions.findAll({ commit }, params)
      if (response) {
        tablePlumb1 = response.data
        total1 = tablePlumb1.length
        page1 = state.currentView.page1
        if (total1 > 0) {
          for (const row of tablePlumb1) {
            if (row.enumeratorStart && Number(row.enumeratorStart) > 0) {
              if (startEnumerator1 === -1) {
                startEnumerator1 = Number(row.enumeratorStart)
                beginWeighting1 = row.beginWeighting
              } else {
                if (startEnumerator1 > Number(row.enumeratorStart)) {
                  startEnumerator1 = Number(row.enumeratorStart)
                  beginWeighting1 = row.beginWeighting
                }
              }
            }
          }
        }
      }
      commit('setListViewProperty', { tablePlumb1, total1, page1, startEnumerator1, beginWeighting1 })
    }

    if (payload.disposition2Id) {
      let tablePlumb2 = []
      let total2 = 0
      let page2 = 1
      let startEnumerator2 = -1
      let beginWeighting2 = null
      params = {
        params: {
          filter: {
            dispositionId: payload.disposition2Id,
            endWeighting: null,
          },
          noCommit: true,
        },
      }
      const response = await actions.findAll({ commit }, params)
      if (response) {
        tablePlumb2 = response.data
        total2 = tablePlumb2.length
        page2 = state.currentView.page2
        if (total2 > 0) {
          for (const row of tablePlumb2) {
            if (row.enumeratorStart && Number(row.enumeratorStart) > 0) {
              if (startEnumerator2 === -1) {
                startEnumerator2 = Number(row.enumeratorStart)
                beginWeighting2 = row.beginWeighting
              } else {
                if (startEnumerator2 > Number(row.enumeratorStart)) {
                  startEnumerator2 = Number(row.enumeratorStart)
                  beginWeighting2 = row.beginWeighting
                }
              }
            }
          }
        }
      }
      commit('setListViewProperty', { tablePlumb2, total2, page2, startEnumerator2, beginWeighting2 })
    }
  },

  async updateDisposition({ commit }, payload) {
    const dispositionData = {
      id: payload.id,
      status: payload.status,
      beginWeighting: payload.beginWeighting,
      endWeighting: payload.endWeighting,
      netto: Number(payload.nettoRegistered) + Number(payload.netto),
      updatedAt: new Date(),
    }
    return await axios
      .put(`/disposition/${dispositionData.id}`, dispositionData)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async createShipUnloading({ commit }, payload) {
    const documentData = {
      date: new Date(),
      markedToDelete: false,
      beginWeighting: payload.beginWeighting,
      endWeighting: payload.endWeighting,
      boxId: payload.boxId,
      netto: payload.netto,
      dispositionId: payload.dispositionId,
      positionId: payload.positionId,
      warehouseId: payload.warehouseId,
      scaleId: payload.scaleId,
      authorId: payload.authorId,
    }
    return await axios
      .post(`/ships_unloading`, documentData)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async getDispositionFilter({ commit }, payload) {
    const dispositionFilter = {
      typeOfDocument: 'Water',
    }

    const arrayStatusIds = []
    const params = {
      params: {
        filter: {
          key: ['OnTheWay', 'NaTerminalu'],
        },
      },
    }
    const response = await axios.get('/disposition_statuses', params)
    if (response) {
      for (const item of response.data) {
        arrayStatusIds.push(item.id)
      }
    }

    dispositionFilter.statusId = arrayStatusIds

    if (payload && payload.weighingStationId && payload.weighingStationId.trim() !== '') {
      dispositionFilter.weighingStationId = payload.weighingStationId
    }

    commit('setListViewProperty', { dispositionFilter })
  },

  async loadWeightings({ commit }, payload) {
    const params = {
      params: {
        scaleNumber: payload.scaleNumber,
        beginWeighting: moment(payload.beginWeighting).format('YYYY-MM-DD HH:mm:ss'),
        endWeighting: moment(payload.endWeighting).format('YYYY-MM-DD HH:mm:ss'),
      },
    }
    const response = await axios.get(`/scales/get_weight_whater`, params)
    if (response) {
      return response.data
    }
  },

  async writeDataToRegister({ commit }, payload) {
    const params = {
      params: {
        dispositionId: payload.dispositionId,
        // beginWeighting: payload.beginWeighting,
        // endWeighting: undefined,
        isNullEndWeighting: true,
        scaleId: payload.scaleId,
      },
    }
    await axios.delete('/plumb_lines', params)

    // const sumCount = (Number(payload.endEnumerator) - payload.startEnumerator) / 1000
    const sumCount = Number(payload.endEnumerator) - Number(payload.startEnumerator)

    const plumbLineData = {
      date: new Date(),
      dispositionId: payload.dispositionId,
      number: 1,
      beginWeighting: payload.beginWeighting,
      scaleId: payload.scaleId,
      boxId: payload.boxId,
      count: sumCount ? sumCount : 0,
      enumeratorStart: payload.startEnumerator,
      enumerator: payload.endEnumerator,
      endWeighting: null,
      // shipUnloadingId: null,
    }

    if (payload.isEndWeighting === true) {
      plumbLineData.endWeighting = payload.endWeighting
      // plumbLineData.shipUnloadingId = payload.shipUnloadingId
    }

    console.log(plumbLineData.scaleId + ': start ' + plumbLineData.enumeratorStart + ' end ' + plumbLineData.enumerator + ', count ' + plumbLineData.count)

    await axios
      .post(`/plumb_lines`, plumbLineData)
      .then((response) => {
        console.log('writeDataToRegister ok')
        return response
      })
      .catch((error) => {
        console.log('writeDataToRegister error: ', error)
        console.error(error)
        return error
      })
  },

  async writeDataToRegister_del({ commit }, payload) {
    const params = {
      params: {
        dispositionId: payload.dispositionId,
        // beginWeighting: payload.beginWeighting,
        // endWeighting: undefined,
        isNullEndWeighting: true,
        scaleId: payload.scaleId,
      },
    }
    await axios.delete('/plumb_lines', params)

    let sumCount = 0
    let maxEnumerator = 0
    for (const row of payload.tablePlumb) {
      if (!(row.date && row.count)) {
        continue
      }
      if (payload.isEndWeighting === true && row.date > payload.endWeighting) {
        break
      }
      sumCount += Number(row.count)
      maxEnumerator = row.enumerator
    }

    const plumbLineData = {
      date: new Date(),
      dispositionId: payload.dispositionId,
      number: 1,
      beginWeighting: payload.beginWeighting,
      scaleId: payload.scaleId,
      boxId: payload.boxId,
      count: sumCount,
      enumerator: maxEnumerator,
      endWeighting: null,
      shipUnloadingId: null,
    }

    if (payload.isEndWeighting === true) {
      plumbLineData.endWeighting = payload.endWeighting
      plumbLineData.shipUnloadingId = payload.shipUnloadingId
    }

    await axios
      .post(`/plumb_lines`, plumbLineData)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async writeDataToRegister_old({ commit }, payload) {
    const params = {
      params: {
        dispositionId: payload.dispositionId,
        // beginWeighting: payload.beginWeighting,
        endWeighting: null,
        scaleId: payload.scaleId,
      },
    }
    await axios.delete('/plumb_lines', params)

    if (payload.isEndWeighting === false) {
      const plumbLineData = {
        dispositionId: payload.dispositionId,
        beginWeighting: payload.beginWeighting,
        scaleId: payload.scaleId,
        boxId: payload.boxId,
      }

      for (const row of payload.tablePlumb) {
        if (!(row.date && row.count && row.enumerator)) {
          continue
        }
        plumbLineData.date = row.date
        plumbLineData.number = row.number
        plumbLineData.count = row.count
        plumbLineData.enumerator = row.enumerator

        await axios
          .post(`/plumb_lines`, plumbLineData)
          .then((response) => {
            return response
          })
          .catch((error) => {
            console.error(error)
            return error
          })
      }
    } else {
      const plumbLineData = {
        dispositionId: payload.dispositionId,
        shipUnloadingId: payload.shipUnloadingId,
        beginWeighting: payload.beginWeighting,
        endWeighting: payload.endWeighting,
        scaleId: payload.scaleId,
        boxId: payload.boxId,
      }

      for (const row of payload.tablePlumb) {
        if (row.date > payload.endWeighting) {
          continue
        }
        plumbLineData.date = row.date
        plumbLineData.number = row.number
        plumbLineData.count = row.count
        plumbLineData.enumerator = row.enumerator
        plumbLineData.endWeighting = payload.endWeighting

        await axios
          .post(`/plumb_lines`, plumbLineData)
          .then((response) => {
            return response
          })
          .catch((error) => {
            console.error(error)
            return error
          })
      }
    }
  },

  resetState({ commit }) {
    commit('resetState')
  },
}

export const getters = {
  currentView(state) {
    return state.currentView
  },

  objectView: (state) => (viewId) => {
    return state.objectViews.find((el) => el.viewId === viewId)
  },

  tablePlumb1(state) {
    return state.currentView.tablePlumb1
  },

  tablePlumb2(state) {
    return state.currentView.tablePlumb2
  },

  page1(state) {
    return state.currentView.page1
  },
  page2(state) {
    return state.currentView.page2
  },
  total1(state) {
    return state.currentView.total1
  },
  total2(state) {
    return state.currentView.total2
  },
  userId(state) {
    return state.currentView.userId
  },

  scale1Id(state) {
    return state.currentView.scale1Id
  },

  scale1(state) {
    return state.currentView.scale1
  },

  scale2Id(state) {
    return state.currentView.scale2Id
  },

  scale2(state) {
    return state.currentView.scale2
  },

  weighingStationId(state) {
    return state.currentView.weighingStationId
  },

  weighingStation(state) {
    return state.currentView.weighingStation
  },

  currentDisposition1(state) {
    return state.currentView.currentDisposition1
  },
  currentDisposition2(state) {
    return state.currentView.currentDisposition2
  },
  dispositionFilter(state) {
    return state.currentView.dispositionFilter
  },
  dispositionFilter1(state) {
    return state.currentView.dispositionFilter1
  },
  dispositionFilter2(state) {
    return state.currentView.dispositionFilter2
  },
  netto1(state) {
    return state.currentView.netto1
  },
  netto2(state) {
    return state.currentView.netto2
  },
  automaticWeightingEnd(state) {
    return state.currentView.automaticWeightingEnd
  },
  beginWeighting1(state) {
    return state.currentView.beginWeighting1
  },
  beginWeighting2(state) {
    return state.currentView.beginWeighting2
  },
  endWeighting1(state) {
    return state.currentView.endWeighting1
  },
  endWeighting2(state) {
    return state.currentView.endWeighting2
  },
  startWeightings1(state) {
    return state.currentView.startWeightings1
  },
  startWeightings2(state) {
    return state.currentView.startWeightings2
  },
  isStarted1(state) {
    return state.currentView.isStarted1
  },
  isStarted2(state) {
    return state.currentView.isStarted2
  },
  isStartWrite1(state) {
    return state.currentView.isStartWrite1
  },
  isStartWrite2(state) {
    return state.currentView.isStartWrite2
  },
  state1Id(state) {
    return state.currentView.state1
  },
  state2Id(state) {
    return state.currentView.state2
  },
  startStopRielaBunkerWhater(state) {
    return state.currentView.startStopRielaBunkerWhater
  },
  isTimer1Worked(state) {
    return state.currentView.isTimer1Worked
  },
  isTimer2Worked(state) {
    return state.currentView.isTimer2Worked
  },
  startEnumerator1(state) {
    return state.currentView.startEnumerator1
  },
  startEnumerator2(state) {
    return state.currentView.startEnumerator2
  },
  nettoEnumerator1(state) {
    return state.currentView.nettoEnumerator1
  },
  nettoEnumerator2(state) {
    return state.currentView.nettoEnumerator2
  },
}
