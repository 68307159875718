import axios from 'axios'
import Pagination from '../../dto/Pagination.json'
import newReclamation from '../../dto/NewReclamation.json'
import Vue from 'vue'
import moment from 'moment'
import _ from 'lodash'

const initState = {
  reclamations: [],
  pagination: _.cloneDeep(Pagination),
  openReclamation: _.cloneDeep(newReclamation),
  openPossitionIndex: 0,
  openCostsIndex: 0,
  showModalPossitionForm: false,
  showModalBulkInteractionForm: false,
  showModalCostsForm: false,
  tabIndex: 0,
  newDocumentMode: true,
  listView: {
    list: [],
    toolBar: [],
    total: 0,
    page: 1,
    limit: 50,
    filters: {
      searchStr: '',
      period: [null, null],
      customer: null,
      clientName: null,
      clientSurname: null,
      responsible: null,
      manager: null,
      executor: null,
      status: null,
      multiplyStatus: false,
      reference: null,
      docType: null,
      executionType: null,
      decision: null,
      state: null,
      addressInv: null,
      subject: null,
    },
    sort: { sortBy: null, sortDesc: false },
  },
  objectViews: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
  setListViewProperty(state: any, payload: any) {
    Object.keys(payload).forEach((key) => {
      state.listView[key] = payload[key]
    })
  },

  setFilters(state: any, filter: any) {
    Object.keys(filter).forEach((key) => {
      state.listView.filters[key] = filter[key]
    })
  },

  setSort(state: any, sort: any) {
    Object.keys(sort).forEach((key) => {
      state.listView.sort[key] = sort[key]
    })
  },

  addObjectView(state: any, view: any) {
    const existView = state.objectViews.find((el: any) => el.viewId === view.viewId)

    if (existView === undefined) {
      state.objectViews.push(view)
    }
  },

  setObjectViewProperty(state: any, payload: any) {
    const index = state.objectViews.findIndex((el: any) => el.viewId === payload.viewId)
    if (index > -1) {
      state.objectViews[index][payload.property] = payload.value
    }
  },

  setObjectViewProperties(state: any, payload: any) {
    const index = state.objectViews.findIndex((el: any) => el.viewId === payload.viewId)
    if (index > -1) {
      Object.keys(payload.props).forEach((key) => {
        state.objectViews[index][key] = payload.props[key]
      })
    }
  },

  setObjectProperty(state: any, payload: any) {
    const index = state.objectViews.findIndex((el: any) => el.viewId === payload.viewId)
    if (index > -1) {
      Vue.set(state.objectViews[index].object, payload.property, payload.value)
    }
  },

  delObjectView(state: any, viewId: any) {
    for (const [i, v] of state.objectViews.entries()) {
      if (v.viewId === viewId) {
        state.objectViews.splice(i, 1)
        break
      }
    }
  },

  resetState(state: any) {
    Object.assign(state, _.cloneDeep(initState))
  },

  setReclamations(state: any, reclamations: any) {
    state.reclamations = reclamations
  },

  setNewDocumentMode(state: any, newDocumentMode: any) {
    state.newDocumentMode = newDocumentMode
  },

  setOpenReclamation(state: any, reclamation: any) {
    if (!reclamation) {
      state.openReclamation = _.cloneDeep(newReclamation)
    } else {
      state.openReclamation = reclamation
    }
  },

  setOpenPossitionIndex(state: any, openPossitionIndex: any) {
    state.openPossitionIndex = openPossitionIndex
  },

  setOpenCostsIndex(state: any, openCostsIndex: any) {
    state.openCostsIndex = openCostsIndex
  },

  setShowModalPossitionForm(state: any, showModalPossitionForm: any) {
    Vue.set(state, 'showModalPossitionForm', showModalPossitionForm)
  },

  setShowModalBulkInteractionForm(state: any, showModalBulkInteractionForm: any) {
    Vue.set(state, 'showModalBulkInteractionForm', showModalBulkInteractionForm)
  },

  setShowModalCostsForm(state: any, showModalCostsForm: any) {
    state.showModalCostsForm = showModalCostsForm
  },

  setReclamationProperty(state: any, { property, value }: any) {
    Vue.set(state.openReclamation, property, value)
  },

  setViewMode(state: any, value: any) {
    state.reclamationViewMode = value
  },

  setTabIndex(state: any, value: any) {
    state.tabIndex = value
  },

  addFiles(state: any, files: any) {
    for (const file of files) {
      state.openReclamation.files.push({
        description: file.name,
        type: file.type,
        size: file.size,
      })
    }
  },

  setReclamationFiles(state: any, files: any) {
    state.openReclamation.files = files
  },

  setPossitionsEditionMode(state: any, editPossitions: any) {
    state.openReclamation.editPossitions = editPossitions
  },

  deleteReclamationFile(state: any, index: any) {
    state.openReclamation.files.splice(index, 1)
  },
}

export const getters = {
  reclamationsList(state: any) {
    return state.reclamations
  },
  newDocumentMode(state: any) {
    return state.newDocumentMode
  },
  openReclamation(state: any) {
    return state.openReclamation
  },
  openPossitionIndex(state: any) {
    return state.openPossitionIndex
  },
  openCostsIndex(state: any) {
    return state.openCostsIndex
  },
  getModalPossitionFormValue(state: any) {
    return state.showModalPossitionForm
  },
  getModalBulkInteractionFormValue(state: any) {
    return state.showModalBulkInteractionForm
  },
  getModalCostsFormValue(state: any) {
    return state.showModalCostsForm
  },
  viewMode(state: any) {
    return state.reclamationViewMode
  },
  tabIndex(state: any) {
    return state.tabIndex
  },

  listView(state: any) {
    return state.listView
  },

  objectView: (state: any) => (viewId: any) => {
    return state.objectViews.find((el: any) => el.viewId === viewId)
  },
}

export const actions = {
  async findAllReclamations({ commit }: any, payload: any) {
    try {
      let list = []
      const response = await axios.get(`/reclamations`, payload)

      if (response) {
        const result = response.data

        if (result.rows) {
          list = result.rows
        } else {
          list = result
        }

        for (let r = 0; r < list.length; r++) {
          const reclamation = list[r]
          let possitionsDescription = ''
          const seenSubjects: { [key: string]: boolean } = {}
          for (let i = 0; i < reclamation.items.length; i++) {
            const curSubject = reclamation.items[i]._reclamationSubject
            if (curSubject && !seenSubjects[curSubject.code]) {
              possitionsDescription = possitionsDescription + curSubject.code + ', '
              seenSubjects[curSubject.code] = true
            }
          }
          reclamation.possitionsDescription = possitionsDescription

          let productionOrderDescription = ''
          if (reclamation.prodorders) {
            for (let p = 0; p < reclamation.prodorders.length; p++) {
              productionOrderDescription = productionOrderDescription + reclamation.prodorders[p].orderNumber + ', '
            }
          }
          reclamation.productionOrderDescription = productionOrderDescription
        }

        if (!payload.noCommit) {
          commit('setListViewProperty', { list })

          let total = 0
          if (result.count) {
            total = result.count
          } else {
            total = list.length
          }

          if (total > 0) {
            const pages = Math.ceil(total / state.listView.limit)
            if (pages >= state.listView.page) {
              commit('setListViewProperty', { total })
            } else {
              commit('setListViewProperty', { total, page: pages })
            }
          } else {
            commit('setListViewProperty', { total, page: 1 })
          }
        }

        return response
      } else {
        if (!payload.noCommit) {
          commit('setListViewProperty', { list: [], total: 0, page: 1 })
        }
        throw new Error('No Content')
      }
    } catch (error) {
      if (!payload.noCommit) {
        commit('setListViewProperty', { list: [], total: 0, page: 1 })
      }
      throw new Error(error)
    }
  },

  async addNew({ commit, dispatch }: any, payload: any) {
    const newObject = _.cloneDeep(newReclamation)

    newObject.id = payload.viewId

    if (payload.byEmail === true && payload.emailData) {
      if (Array.isArray(payload.emailData)) {
        newObject.reference = payload.emailData
          .map((el: any) => {
            return `${el.title} (${el.from})`
          })
          .join(', ')

        newObject.files = []
        newObject.emails = payload.emailData
      } else {
        if (payload.emailData.title) {
          newObject.reference = `${payload.emailData.title} (${payload.emailData.from})`
        }

        if (payload.emailData.filesAtHardDrive === true) {
          newObject.files = []

          let emailFiles = payload.emailData.files

          if (!emailFiles) {
            await dispatch('incomingEmails/getFiles', { params: { emailId: payload.emailData.id } }, { root: true })
              .then(async (response: any) => {
                if (response.status === 200) {
                  emailFiles = response.data
                }
              })
              .catch((error: any) => {
                console.error(error)
              })
          }

          if (emailFiles) {
            for (const file of emailFiles) {
              if (file.contentDisposition === 'attachment' || file.contentType === 'application/pdf') {
                await dispatch('incomingEmails/getFile', { id: file.id }, { root: true })
                  .then((response: any) => {
                    if (response.status === 200) {
                      const itemFile = new File([response.data], file.filename, { type: file.contentType })
                      newObject.files.push(itemFile)
                    }
                  })
                  .catch((error: any) => {
                    throw error
                  })
              }
            }
          }
        }
      }
    }
    commit('addObjectView', {
      viewId: payload.viewId,
      editMode: 'NEW',
      nevVersion: payload.nevVersion,
      byEmail: payload.byEmail,
      emailData: payload.emailData,
      object: newObject,
    })

    return true
  },

  async findReclamationTasks({ commit }: any, reclamationId: any) {
    const filterStr = {
      params: {
        filter: {
          ownerType: 'reclamation',
          ownerId: reclamationId,
        },
        getAllTasks: true,
      },
    }

    await axios.get(`/tasks`, filterStr).then((response) => {
      const tasks = response.data
      tasks.forEach((el: any) => {
        el.createdAt = moment(el.createdAt).format('DD.MM.YYYY HH:mm:ss')
        el.date = moment(el.date).format('DD.MM.YYYY HH:mm:ss')
        el.executionPeriod = moment(el.executionPeriod).format('DD.MM.YYYY HH:mm')

        el.icon = 'ri-upload-line'
        el.title = el.name
        el.text = 'Wykonawca ' + el.executorName
        el.subtext = 'Utworzono: ' + el.date
        if (el.executed) {
          el.color = 'success'
          el.subtext = el.subtext + ' wykonano: ' + moment(el.executionDate).format('DD.MM.YYYY HH:mm')
        } else {
          el.color = 'info'
        }
      })
      commit('setReclamationProperty', { property: 'tasks', value: tasks })
    })
  },

  async findByPk({ commit }: any, payload: any) {
    return axios
      .get(`/reclamations/${payload.params.id}`, payload.query)
      .then((response) => {
        const object = response.data
        object.createdAt = moment(object.createdAt).format('DD.MM.YYYY hh:mm:ss')

        if (!payload.noCommit) {
          commit('addObjectView', { viewId: payload.params.id, object, tabIndex: 0 })
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async getReclamationActionTypes() {
    const actionTypes = [
      {
        id: 0,
        description: '-Nie wybrano-',
      },
      {
        id: 1,
        description: 'Zmiana statusu',
      },
      {
        id: 2,
        description: 'Dodanie komentarza',
      },
      {
        id: 3,
        description: 'Dodanie pliku',
      },
      {
        id: 4,
        description: 'Decyzja',
      },
      {
        id: 5,
        description: 'Dodanie zlecenia serwisowego',
      },
      {
        id: 6,
        description: 'Monit',
      },
    ]

    return actionTypes
  },

  async create({ commit, dispatch }: any, payload: any) {
    return axios
      .post(`/reclamations`, payload)
      .then((response) => {
        return response
      })
      .catch((err) => {
        console.log('error in create reclamation store: ', err)
      })
  },

  async update({ commit }: any, payload: any) {
    return axios
      .put(`/reclamations/${payload.id}`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async changeDeletionMark({ commit }: any, payload: any) {
    return axios
      .post(`/reclamations/change_deletion_mark`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async uploadFiles({ commit, getters }: any, payload: any) {
    const formData = new FormData()
    payload.files.map((file: any) => formData.append('files', file))

    const dataParams = {
      parentId: payload.params.parentId,
      description: payload.params.description,
      verification_protocol: payload.params.verification_protocol,
      files: payload.params.files,
    }

    formData.append('data', JSON.stringify(dataParams))

    return axios
      .post(`reclamations/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async getFiles({ commit }: any, payload: any) {
    return axios
      .get(`reclamations/files`, payload)
      .then((response) => {
        // commit('setOrderFiles', response.data)
        return response.data
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  async deleteFile({ commit }: any, params: any) {
    return axios
      .delete(`/file/${params.id}`)
      .then((response) => {
        if (response.status === 200) {
          commit('deleteOrderFile', params.index)
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  async openFile({ commit }: any, params: any) {
    return axios
      .get(`/reclamations/files/${params.id}`, { responseType: 'blob' })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: params.type })
          const fileLink = document.createElement('a')
          fileLink.href = window.URL.createObjectURL(blob)
          if (
            params.open === true &&
            (params.type === 'application/pdf' || params.type === 'image/jpeg' || params.type === 'image/png' || params.type === 'application/vnd.ms-excel')
          ) {
            fileLink.target = '_blank'
            fileLink.rel = 'noopener noreferrer'
          } else {
            fileLink.setAttribute('download', params.name)
          }
          document.body.appendChild(fileLink)
          fileLink.click()
        }

        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  resetState({ commit }: any) {
    commit('resetState')
  },
}
