import { ActionContext } from 'vuex'
import { IState } from './state'
import axios from 'axios'
import _ from 'lodash'

type HundlerFunction = (...args: any[]) => void

export async function findAll(context: ActionContext<IState, any>, apiUrl: string, payload: any, listHundler: HundlerFunction | undefined = undefined) {
  const listView = { list: [], total: 0, page: 1, viewId: payload.viewId }

  return axios
    .get(`/${apiUrl}`, payload)
    .then((response) => {
      if (!payload.noCommit) {
        const result = response.data

        if (result.rows) {
          listView.list = Object.freeze(result.rows)
        } else {
          listView.list = Object.freeze(result)
        }

        listView.total = result.count ? result.count : listView.list.length
        let page = context.state.listView.page

        if (listView.total > 0) {
          const pages = Math.ceil(listView.total / context.state.listView.limit)
          if (pages < context.state.listView.page) {
            page = pages
          }
        } else {
          page = 1
        }

        listView.page = page

        if (listHundler) {
          listHundler(listView, context)
        }

        context.commit('setListViewProperty', listView)
      }

      return response
    })
    .catch((error) => {
      if (!payload.noCommit) {
        if (listHundler) {
          listHundler(listView, context)
        }
        context.commit('setListViewProperty', listView)
      }
      throw error
    })
}

export async function findByPk(context: ActionContext<IState, any>, apiUrl: string, payload: any, itemHundler: HundlerFunction | undefined = undefined) {
  if (!payload.noCommit && !payload.update) {
    if (context.state.objectViews.some((v) => v.viewId === payload.params.id)) return { status: 200 }
  }

  return axios
    .get(`/${apiUrl}/${payload.params.id}`, payload.query)
    .then(async (response) => {
      if (!payload.noCommit) {
        const viewObject = { viewId: payload.params.id, object: response.data, modified: false, views: [], attrs: {}, settings: {} }

        if (itemHundler) {
          await itemHundler(viewObject, context)
        }

        if (!payload.update) {
          context.commit('addObjectView', viewObject)
        } else {
          context.commit('setObjectViewProperties', {
            viewId: viewObject.viewId,
            props: viewObject,
          })
        }
      }

      return response
    })
    .catch((error) => {
      throw error
    })
}

export async function findPredefined(context: ActionContext<IState, any>, apiUrl: string, payload: any, itemHundler: HundlerFunction | undefined = undefined) {
  return axios
    .get(`/${apiUrl}/predefined/${payload.params.name}`, payload.query)
    .then(async (response) => {
      if (itemHundler) {
        await itemHundler(response, context)
      }

      return response
    })
    .catch((error) => {
      throw error
    })
}

export async function addNewItem(context: ActionContext<IState, any>, basicData: any, payload: any, beforeAddNew: HundlerFunction | undefined = undefined) {
  let viewId
  let object: any = {}

  if (basicData.appObject) {
    await axios
      .get('/app/object_meta', { params: { objectType: basicData.appObject.model, withTableParts: true } })
      .then((response) => {
        const objectData = response.data

        for (const objectField of objectData) {
          if (objectField.type === 'VIRTUAL' || objectField.name === 'id' || objectField.name === 'createdAt' || objectField.name === 'updatedAt') {
            continue
          }

          if (objectField.type === 'tablePart') {
            object[objectField.name] = []
          } else {
            if (objectField.defaultValue) {
              object[objectField.name] = objectField.defaultValue
            } else if (objectField.allowNull !== false) {
              object[objectField.name] = null
            } else {
              const fieldType = objectField.type.toLowerCase()

              switch (fieldType) {
                case 'string':
                  object[objectField.name] = ''
                  break
                case 'text':
                  object[objectField.name] = ''
                  break
                case 'integer':
                  object[objectField.name] = 0
                  break
                case 'decimal':
                  object[objectField.name] = '0.00'
                  break
                case 'boolean':
                  object[objectField.name] = false
                  break
                case 'uuid':
                  object[objectField.name] = null
                  break
                case 'enum':
                  object[objectField.name] = null
                  break
                case 'date':
                  object[objectField.name] = null
                  break
              }
            }
          }
        }
      })
      .catch((error) => {
        console.warn(`Error get meta for ${basicData.appObject?.model}: ${error}`)
      })
  } else {
    object = _.cloneDeep(basicData.basicObject)
  }

  object.isNew = true

  if (typeof payload === 'string') {
    viewId = payload
    object.id = payload
  } else {
    viewId = payload.id

    for (const objectKey in payload) {
      object[objectKey] = payload[objectKey]
    }
  }

  const viewObject = { viewId, object, modified: false }

  if (beforeAddNew) {
    await beforeAddNew(viewObject, context, basicData)
  }

  context.commit('addObjectView', viewObject)
}

export async function createItem(
  context: ActionContext<IState, any>,
  apiUrl: string,
  payload: any,
  beforeCreate: HundlerFunction | undefined = undefined,
  afterCreate: HundlerFunction | undefined = undefined
) {
  if (beforeCreate) {
    beforeCreate(payload)
  }

  return axios
    .post(`/${apiUrl}`, payload)
    .then((response) => {
      if (afterCreate) {
        afterCreate(response)
      }

      return response
    })
    .catch((error) => {
      throw error
    })
}

export async function updateItem(
  context: ActionContext<IState, any>,
  apiUrl: string,
  payload: any,
  beforeUpdate: HundlerFunction | undefined = undefined,
  afterUpdate: HundlerFunction | undefined = undefined
) {
  if (beforeUpdate) {
    beforeUpdate(payload)
  }

  return axios
    .put(`/${apiUrl}/${payload.id}`, payload)
    .then((response) => {
      if (afterUpdate) {
        afterUpdate(response)
      }

      return response
    })
    .catch((error) => {
      throw error
    })
}

export async function confirmItem(
  context: ActionContext<IState, any>,
  apiUrl: string,
  payload: any,
  beforeConfirm: HundlerFunction | undefined = undefined,
  afterConfirm: HundlerFunction | undefined = undefined
) {
  if (beforeConfirm) {
    beforeConfirm(payload)
  }

  return axios
    .put(`/${apiUrl}/confirm/${payload.id}`, payload)
    .then((response) => {
      if (afterConfirm) {
        afterConfirm(response)
      }

      return response
    })
    .catch((error) => {
      throw error
    })
}

export async function undoItemConfirm(
  context: ActionContext<IState, any>,
  apiUrl: string,
  payload: any,
  beforeUndoConfirm: HundlerFunction | undefined = undefined,
  afterUndoConfirm: HundlerFunction | undefined = undefined
) {
  if (beforeUndoConfirm) {
    beforeUndoConfirm(payload)
  }

  return axios
    .put(`/${apiUrl}/undo_confirm/${payload.id}`, payload)
    .then((response) => {
      if (afterUndoConfirm) {
        afterUndoConfirm(response)
      }

      return response
    })
    .catch((error) => {
      throw error
    })
}

export async function changeDeletionMark(
  context: ActionContext<IState, any>,
  apiUrl: string,
  payload: any,
  beforeChangeDM: HundlerFunction | undefined = undefined,
  afterChangeDM: HundlerFunction | undefined = undefined
) {
  if (beforeChangeDM) {
    beforeChangeDM(payload)
  }

  return await axios
    .post(`/${apiUrl}/change_deletion_mark`, payload)
    .then((response) => {
      if (afterChangeDM) {
        afterChangeDM(response)
      }

      return response
    })
    .catch((error) => {
      throw error
    })
}

export async function deleteItem(
  context: ActionContext<IState, any>,
  apiUrl: string,
  payload: any,
  beforeDelete: HundlerFunction | undefined = undefined,
  afterDelete: HundlerFunction | undefined = undefined
) {
  if (beforeDelete) {
    beforeDelete(payload)
  }

  return axios
    .delete(`/${apiUrl}/${payload.id}`, payload)
    .then((response) => {
      if (afterDelete) {
        afterDelete(response)
      }

      return response
    })
    .catch((error) => {
      throw error
    })
}

export function resetState(context: ActionContext<IState, any>) {
  context.commit('resetState')
}
