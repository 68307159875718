import axios from 'axios'
import _ from 'lodash'

const initState = {
  currentView: {
    list1: [],
    list2: [],
    userId: null,
    currentWarehouseId: null,
    currentDeliveryOrderId: null,
    currentDispositionId: null,
    selectedStatuses: [],
    searchStr: '',
    searchStrOrder: '',
  },
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
  setListViewProperty(state: any, payload: any) {
    Object.keys(payload).forEach((key) => {
      state.currentView[key] = payload[key]
    })
  },

  setFilters(state: any, filter: any) {
    Object.keys(filter).forEach((key) => {
      state.listView.filters[key] = filter[key]
    })
  },

  setSort(state: any, sort: any) {
    Object.keys(sort).forEach((key) => {
      state.listView.sort[key] = sort[key]
    })
  },

  addObjectView(state: any, view: any) {
    const existView = state.objectViews.find((el: any) => el.viewId === view.viewId)

    if (existView === undefined) {
      state.objectViews.push(view)
    }
  },

  setObjectViewProperty(state: any, payload: any) {
    state.currentView[payload.property] = payload.value
  },

  setObjectProperty(state: any, payload: any) {
    const index = state.objectViews.findIndex((el: any) => el.viewId === payload.viewId)
    if (index > -1) {
      state.objectViews[index].object[payload.property] = payload.value
    }
  },

  delObjectView(state: any, viewId: any) {
    for (const [i, v] of state.objectViews.entries()) {
      if (v.viewId === viewId) {
        state.objectViews.splice(i, 1)
        break
      }
    }
  },

  setCurrentView(state: any, currentView: any) {
    state.currentView = currentView
  },
  resetState(state: any) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const actions = {
  async getOrdersList({ commit }: any, payload: any) {
    return axios
      .get(`/dispositions/cars_qeue_orders_list`, payload)
      .then((response) => {
        const list1 = response.data
        commit('setListViewProperty', { list1 })
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async getDispositionsList({ commit }: any, payload: any) {
    const params = {
      params: {
        filter: { orderId: payload.currentDeliveryOrderId, searchStr: payload.searchStr },
      },
    }

    return axios
      .get(`/dispositions/cars_qeue_dispositions_list`, params)
      .then((response) => {
        const list2 = response.data
        commit('setListViewProperty', { list2 })
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async getDispositionsByTicket(context: any, payload: any) {
    return axios
      .get(`/dispositions/cars_qeue_dispositions_by_ticket`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async resetTicket({ commit }: any, payload: any) {
    return axios
      .put(`/dispositions/reset_driver_ticket`, payload)
      .then((response) => {
        console.log('response = ', response)

        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async fillArrivalDateDriver({ commit }: any, payload: any) {
    return axios
      .post(`/dispositions/cars_queue/fill-arrival-date-driver`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async setArrivalDateDriver({ commit }: any, payload: any) {
    return axios
      .post(`/dispositions/cars_queue/set-arrival-date-driver`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  resetState({ commit }: any) {
    commit('resetState')
  },
}

export const getters = {
  currentView(state: any) {
    return state.currentView
  },

  objectView: (state: any) => (viewId: any) => {
    return state.objectViews.find((el: any) => el.viewId === viewId)
  },

  list1(state: any) {
    return state.currentView.list1
  },

  list2(state: any) {
    return state.currentView.list2
  },

  currentDeliveryOrderId(state: any) {
    return state.currentView.currentDeliveryOrderId
  },
}
