import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import Vue from 'vue'
import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IReportState, IReportView, IReportSettings } from '../types/ReportType'

const path = 'report'

const initState: IReportState = {
  reportViews: [],
}

export const state: IReportState = _.cloneDeep(initState)

export const mutations: MutationTree<IReportState> = {
  addReportView(state: IReportState, reportView: IReportView) {
    state.reportViews.push(reportView)
  },

  setReportSettings(state: IReportState, payload) {
    const index = state.reportViews.findIndex((el) => el.reportId === payload.reportId)
    if (index > -1) {
      Vue.set(state.reportViews[index], 'settings', payload.settings)
    }
  },

  setReportSetting(state: IReportState, payload) {
    const index = state.reportViews.findIndex((el) => el.reportId === payload.reportId)
    if (index > -1) {
      Vue.set(state.reportViews[index].settings, payload.property, payload.value)
    }
  },

  setReportState(state: IReportState, payload) {
    const index = state.reportViews.findIndex((el) => el.reportId === payload.reportId)
    if (index > -1) {
      Vue.set(state.reportViews[index].state, payload.property, payload.value)
    }
  },

  setReportResult(state: IReportState, payload) {
    const index = state.reportViews.findIndex((el) => el.reportId === payload.reportId)
    if (index > -1) {
      Vue.set(state.reportViews[index], 'result', payload.result)
    }
  },

  setReportResultProperty(state: IReportState, payload) {
    const index = state.reportViews.findIndex((el) => el.reportId === payload.reportId)
    if (index > -1) {
      Vue.set(state.reportViews[index].result, payload.property, payload.value)
    }
  },

  setReportVariants(state: IReportState, payload) {
    const index = state.reportViews.findIndex((el) => el.reportId === payload.reportId)
    if (index > -1) {
      Vue.set(state.reportViews[index], 'reportVariants', payload.reportVariants)
    }
  },

  setReportProperty(state: IReportState, payload) {
    const index = state.reportViews.findIndex((el) => el.reportId === payload.reportId)
    if (index > -1) {
      Vue.set(state.reportViews[index], payload.property, payload.value)
    }
  },

  setReportProperties(state: IReportState, payload) {
    const index = state.reportViews.findIndex((el) => el.reportId === payload.reportId)
    if (index > -1) {
      Object.keys(payload.props).forEach((key) => {
        Vue.set(state.reportViews[index], key, payload.props[key])
      })
    }
  },

  setProperty(state: IReportState, payload: any) {
    Vue.set(payload.object, payload.property, payload.value)
  },

  setProperties(state: IReportState, payload: any) {
    Object.keys(payload.props).forEach((key) => {
      Vue.set(payload.object, key, payload.props[key])
    })
  },

  deleteItem(state: IReportState, payload: any) {
    payload.items.splice(payload.index, 1)
  },

  addItem(state: IReportState, payload: any) {
    if (payload.index !== undefined) {
      payload.items.splice(payload.index, 0, payload.item)
    } else {
      payload.items.push(payload.item)
    }
  },

  moveItem(state: IReportState, payload: any) {
    if (payload.toIndex < 0 || payload.toIndex > payload.items.length - 1) {
      return
    }

    const itemFrom = payload.items.splice(payload.fromIndex, 1)
    payload.items.splice(payload.toIndex, 0, itemFrom[0])
  },

  delObjectView(state: IReportState, reportId) {
    for (const [i, v] of state.reportViews.entries()) {
      if (v.reportId === reportId) {
        state.reportViews.splice(i, 1)
        break
      }
    }
  },

  resetState(state) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const actions: ActionTree<IReportState, any> = {
  async getReportSettings({ commit }, payload) {
    if (state.reportViews.some((v) => v.reportId === payload.reportId)) {
      return { status: 200 }
    }

    return await axios
      .get(`/${path}/settings/${payload.reportId}`)
      .then((response) => {
        if (response.status === 200) {
          const currentDate = new Date()
          const reportSettings = response.data

          const settings: IReportSettings = {
            periodType: reportSettings.periodType,
            dateType: reportSettings.dateType,
            name: reportSettings.name,
            title: reportSettings.title,
            description: reportSettings.description,
            date: reportSettings.date ? moment(reportSettings.date).toDate() : moment(currentDate).startOf('day').toDate(),
            dateFrom: reportSettings.dateFrom ? moment(reportSettings.dateFrom).toDate() : moment(currentDate).startOf('month').toDate(),
            dateTo: reportSettings.dateTo ? moment(reportSettings.dateTo).toDate() : moment(currentDate).endOf('month').toDate(),
            fields: reportSettings.fields,
            filters: reportSettings.filters || [],
            groups: reportSettings.groups || [],
            calcs: reportSettings.calcs || [],
            sorts: reportSettings.sorts || [],
            showYTotal: reportSettings.showYTotal,
            showXTotal: reportSettings.showXTotal,
            totals: reportSettings.calcs.filter((el: any) => el.isTotal === true),
            hideDetails: reportSettings.hideDetails || false,
            maxLimit: 50000,
            pageSize: reportSettings.pageSize || 'A4',
            pageOrientation: reportSettings.pageOrientation || 'landscape',
            pageFontSize: reportSettings.pageFontSize || 12,
            margins: {
              top: reportSettings.pageMarginTop || 20,
              left: reportSettings.pageMarginLeft || 20,
              bottom: reportSettings.pageMarginBottom || 20,
              right: reportSettings.pageMarginRight || 20,
            },
          }

          const reportView: IReportView = {
            reportId: payload.reportId,
            settings: settings,
            result: {
              limitError: false,
              generated: false,
              total: 0,
            },
            state: {
              selGroupLevel: 0,
              settingsIsOpen: false,
              isGenerate: false,
              page: 1,
              limit: 100,
            },
            reportVariants: [],
          }

          commit('addReportView', reportView)
        }

        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async getReportResult({ commit }, payload) {
    return await axios
      .post(`/${path}/result`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async exportToExcel({ commit }, payload) {
    return await axios
      .post(`/${path}/export_excel`, payload.params, { responseType: 'blob' })
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async exportToPdf({ commit }, payload) {
    return await axios
      .post(`/${path}/export_pdf`, payload.params, { responseType: 'blob' })
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async getReportVariants({ commit }, payload) {
    return await axios
      .get(`/report_variants`, { params: payload })
      .then((response) => {
        if (response.status === 200) {
          const reportVariants = []
          let rowNumber = 0
          for (const variant of response.data) {
            reportVariants.push({
              id: variant.id,
              index: ++rowNumber,
              name: variant.variantName,
              user: !variant.userId === null,
            })
          }

          commit('setReportVariants', {
            reportId: payload.reportId,
            reportVariants,
          })
        }

        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async getReportVariant(context, payload) {
    return await axios
      .get(`/report_variants/${payload.params.id}`, payload.params)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async createReportVariant(context, payload) {
    return await axios
      .post(`/report_variants`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async updateReportVariant(context, payload) {
    return await axios
      .put(`/report_variants/${payload.id}`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async deleteReportVariant(context, payload) {
    return await axios
      .delete(`/report_variants/${payload.params.id}`, payload.params)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  resetState({ commit }) {
    commit('resetState')
  },
}

export const getters: GetterTree<IReportState, any> = {
  reportView: (state) => (reportId: string) => {
    return state.reportViews.find((el) => el.reportId === reportId)
  },
}
