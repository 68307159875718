<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4">2022 - {{ new Date().getFullYear() }} © INTELEKTSOFT - SynergyCore</div>
        <div class="col-md-5">
          <span v-if="!$store.state.auth?.currentUser?.license?.active" class="float-left red-text">
            <strong>
              {{
                $store.state.auth?.currentUser?.license?.number === 'none'
                  ? 'BRAK AKTYWACJI LICENCJI!!! Proszę się skontaktować z administratorem systemu.'
                  : 'BRAK DOSTĘPNEJ LICENCJI!!! Proszę się skontaktować z administratorem systemu.'
              }}
            </strong>
          </span>
        </div>
        <div class="col-md-3">
          <span class="float-right" :class="{ 'normal-text': !isFindNewVersion, 'red-text': isFindNewVersion }">
            {{ newVersionText }} Version [client: {{ $store.state.app?.info?.clientVersion }}, server: {{ $store.state.app?.info?.serverVersion }}]</span
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { showMessageBox } from '@/utils/commonUse.js'
export default {
  name: 'PageFooter',
  data() {
    return {
      newVersionText: '',
      isFindNewVersion: false,
      isPosibleStart: true,
    }
  },

  computed: {
    appInfo: {
      get() {
        return this.$store.state.app?.info
      },
    },
  },

  watch: {
    appInfo() {
      this.checkingVersion()
    },
  },

  mounted() {
    this.checkingVersion()
  },

  methods: {
    checkingVersion() {
      const clientVersion = this.appInfo.clientVersion
      const serverVersion = this.appInfo.serverVersion

      if (!clientVersion) {
        return
      }
      if (!serverVersion) {
        return
      }

      let serverSection12 = ''
      let serverSection3 = ''
      const serverVersionLength = serverVersion.length
      for (let i = 0; i < serverVersionLength; i++) {
        const symb = serverVersion.slice(serverVersionLength - i - 1, serverVersionLength - i)
        if (symb === '.') {
          serverSection12 = serverVersion.slice(0, serverVersionLength - i - 1)
          serverSection3 = serverVersion.slice(serverVersionLength - i, serverVersionLength)
          break
        }
      }

      let clientSection12 = ''
      let clientSection3 = ''
      const clientVersionLength = clientVersion.length
      for (let i = 0; i < clientVersionLength; i++) {
        const symb = clientVersion.slice(clientVersionLength - i - 1, clientVersionLength - i)
        if (symb === '.') {
          clientSection12 = clientVersion.slice(0, clientVersionLength - i - 1)
          clientSection3 = clientVersion.slice(clientVersionLength - i, clientVersionLength)
          break
        }
      }

      if (clientSection3 !== serverSection3) {
        this.isFindNewVersion = true
        this.newVersionText = 'New version available. '
      }
      if (clientSection12 !== serverSection12) {
        this.isPosibleStart = false
      }

      if (this.isPosibleStart === false) {
        showMessageBox(this, 'Aplikacja jest nieaktualna. Wymagana aktualizacja.', 40, true, 'lg')
      }
    },
  },
}
</script>

<style lang="scss">
.red-text {
  color: red;
}
.normal-text {
  color: rgb(178, 178, 178);
}
</style>