import axios from 'axios'
import newItem from '../../dto/newBpExemplar.json'
import Pagination from '../../dto/Pagination.json'
import Vue from 'vue'
import _ from 'lodash'
import { findAll, findByPk } from '../service/actions'
import { ActionContext } from 'vuex'
import { IState } from '@/store/service/state'

const apiUrl = 'bp_exemplars'

const initState = {
  itemsListView: {
    items: [],
    pagination: _.cloneDeep(Pagination),
    filter: '',
  },
  openStageIndex: 0,
  showModalStageForm: false,
  itemsList: [],
  pagination: _.cloneDeep(Pagination),
  openItem: _.cloneDeep(newItem),
  tabIndex: 0,
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
  setOpenItemProperty(state: any, { property, value }: any) {
    Vue.set(state.openItem, property, value)
  },

  setOpenItemIndex(state: any, openStageIndex: any) {
    state.openItemIndex = openStageIndex
  },

  setShowModalItemForm(state: any, showModalStageForm: any) {
    state.showModalItemForm = showModalStageForm
  },

  setItems(state: any, items: any) {
    state.itemsListView.items = items
  },

  updatePagination(state: any, pagination: any) {
    state.itemsListView.pagination = pagination
  },

  setCurrentPage(state: any, page: any) {
    state.itemsListView.pagination.page = page
  },

  setListTotal(state: any, total: any) {
    state.itemsListView.pagination.total = total
  },

  setListLimit(state: any, limit: any) {
    state.itemsListView.pagination.limit = limit
  },

  updateFilter(state: any, filter: any) {
    state.itemsListView.filter = filter
  },

  async setOpenItem(state: any, item: any) {
    if (!item) {
      state.openItem = _.cloneDeep(newItem)
    } else {
      if (item.stages) {
        const parsedObject = JSON.parse(item.stages)
        item.stages = parsedObject.array
      }
      if (item.stageSettingsList) {
        const parsedObject = JSON.parse(item.stageSettingsList)
        item.stageSettingsList = parsedObject.array
      }
      state.openItem = item
    }
  },

  setTabIndex(state: any, value: any) {
    state.tabIndex = value
  },

  resetState(state: any) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const getters = {
  itemsList(state: any) {
    return state.itemsListView.items
  },
  itemsListView(state: any) {
    return state.itemsListView
  },
  openItem(state: any) {
    return state.openItem
  },
  tabIndex(state: any) {
    return state.tabIndex
  },
  openItemIndex(state: any) {
    return state.openItemIndex
  },
  getModalItemFormValue(state: any) {
    return state.showModalItemForm
  },
}

export const actions = {
  async findAll(context: ActionContext<IState, any>, payload: any) {
    return findAll(context, apiUrl, payload)
  },

  async findByPk(context: ActionContext<IState, any>, payload: any) {
    return findByPk(context, apiUrl, payload)
  },

  async create(context: ActionContext<IState, any>, itemData: any) {
    const saveData = _.cloneDeep(itemData)

    const response = await axios.post(`/${apiUrl}`, saveData)
    if (response?.status === 200) {
      return true
    } else {
      return false
    }
  },

  async updateItem(context: ActionContext<IState, any>, itemData: any) {
    const response = await axios.put(`/${apiUrl}/${itemData.id}`, itemData)
    if (response?.status === 200) {
      return true
    } else return false
  },

  async deleteItem(context: ActionContext<IState, any>, itemData: any) {
    try {
      const response = await axios.delete(`/${apiUrl}/${itemData.id}`, itemData)
      if (response?.status === 200) {
        return true
      } else return false
    } catch (error) {
      console.error(error)
      return false
    }
  },

  async executeAction(context: ActionContext<IState, any>, data: any) {
    const res = await axios.post(`/${apiUrl}/execute_action`, data)
    return res
  },

  resetState({ commit }: any) {
    commit('resetState')
  },
}
