import axios from 'axios'

export const actions = {
  async findAll(context: any, reclamationId: any) {
    const params = {}
    if (reclamationId) {
      //@ts-ignore
      params.parentId = reclamationId
    }

    return await axios
      .get(`/reclamations/emails`, { params })
      .then((response) => {
        if (response.status === 200) {
          return response.data.responseData
        } else throw new Error('No content')
      })
      .catch((error) => {
        throw error
      })
  },

  async findByPk(context: any, payload: any) {
    return axios
      .get(`/reclamations/emails/${payload.id}`, payload)
      .then((response) => {
        if (response.status === 200) {
          const emailData = response.data.responseData
          return emailData
        } else {
          throw new Error('No Content')
        }
      })
      .catch((error) => {
        throw error
      })
  },

  async addItem(context: any, payload: any) {
    return await axios
      .post(`/reclamations/emails`, payload)
      .then((response) => {
        if (response.status === 200) {
          return true
        } else return false
      })
      .catch((error) => {
        throw error
      })
  },

  async updateItem(context: any, payload: any) {
    return await axios
      .put(`/reclamations/emails`, payload)
      .then((response) => {
        if (response.status === 200) {
          return true
        } else return false
      })
      .catch((error) => {
        throw error
      })
  },

  async deleteItem(context: any, payload: any) {
    return await axios
      .post(`/reclamations/emails`, payload)
      .then((response) => {
        if (response.status === 200) {
          return true
        } else return false
      })
      .catch((error) => {
        throw error
      })
  },
}
