import customerRequestsActions from './customerRequests-actions'

export default {
  executeConditionCode(str, args) {
    const conditionFunction = new Function('args', str)
    const conditionRes = conditionFunction(args)

    return conditionRes
  },

  async executeAsyncConditionCode(str, args, $store) {
    const objectActions = {
      customerRequestsActions,
    }
    const AsyncFunction = Object.getPrototypeOf(async function () {}).constructor
    const asyncFn = new AsyncFunction('args, $store, objectActions', str)
    const result = await asyncFn(args, $store, objectActions)

    return result
  },

  async executeAction($store, payload) {
    const data = { ...payload }
    const isManual = Boolean(data.isManual && !data.currentUser?.externalUser && data.currentUser?.fullRights)
    const users = await $store.dispatch('users/findAll', { noCommit: true }).then((res) => res.data)
    const statuses = await this.getOwnerObjStatuses($store, data.ownerType)
    const ownerObj = await this.getOwnerObj($store, data)
    const bpExemplar = await this.getBPExemplar($store, data.bpExemplarId || null)

    let actionResponse = null
    if (!isManual) {
      actionResponse = await this.executeAsyncConditionCode(
        data.action.script,
        {
          [data.ownerType]: ownerObj,
          executors: users,
          statuses,
        },
        $store
      )

      if (data.action?.allowChangeExecutor && data.customExecutor) {
        actionResponse.executorId = data.customExecutor?.id
      }

      data.actionResponse = actionResponse
    }

    const currentStageHistory = $store.state.bpHistories.histories.find((history) => {
      const stage = history.stage
      if (
        history.exemplarId === bpExemplar?.id
          ? bpExemplar?.id
          : ownerObj.bpExemplar.id && stage.id === bpExemplar?.stage.id
          ? bpExemplar?.stage.id
          : ownerObj.bpExemplar.stage.id && !history.finishedAt
      )
        return true
    })
    data.bpHistoryId = currentStageHistory.id

    let nextStage = null
    if ((isManual || data.action?.allowChangeStage) && data.customStageId) {
      nextStage = this.findStageById(data.customStageId, bpExemplar, ownerObj)
    } else {
      const nextStageId = await this.findNextStageId(actionResponse, ownerObj, $store, bpExemplar)
      nextStage = this.findStageById(nextStageId, bpExemplar, ownerObj)
    }

    if (isManual || data.action?.allowChangeExecutor) {
      nextStage = { ...nextStage, valueType: 'user', value: data.customExecutor.id }
    }
    data.nextStage = nextStage

    const res = await $store.dispatch('bpExemplars/executeAction', data)

    if (res.status !== 200) {
      return 'error'
    }

    if (!nextStage) {
      return 'not_found_next_stage'
    }

    return 'success'
  },

  async getBPExemplar($store, exemplarId) {
    const bpExemplar = await $store.dispatch('bpExemplars/findByPk', { noCommit: true, params: { id: exemplarId } })
    return bpExemplar.data
  },

  findStageById(id, bpExemplar, ownerObj) {
    let stage
    if (bpExemplar) {
      stage = bpExemplar.definition.stages.find((e) => e.uuid === id)
    } else {
      stage = ownerObj.definition.stages.array.find((e) => e.uuid === id)
    }
    return stage
  },

  async findNextStageId(actionResponse, ownerObj, $store, bpExemplar) {
    let nextStageId = bpExemplar ? bpExemplar.stage?.nextStage : ownerObj.stage?.nextStage
    const nextStage = this.findStageById(nextStageId, bpExemplar, ownerObj)

    if (nextStage?.isCondition) {
      const args = {
        customerRequest: { ...ownerObj, ...actionResponse },
      }

      for (const cond of nextStage.conditions) {
        const res = await this.executeAsyncConditionCode(cond.conditionCode, args, $store)
        if (res === true) {
          nextStageId = cond.nextStage
          break
        }
      }
    }

    if (nextStageId === nextStage.uuid && nextStage?.isCondition) {
      return null
    }
    return nextStageId
  },

  async getOwnerObj($store, { ownerType, ownerId }) {
    const storeName = ownerType
    if (!storeName) return null

    const response = await $store.dispatch(`${storeName}/findByPk`, { noCommit: true, params: { id: ownerId } })

    return response.data || null
  },

  async getOwnerObjStatuses($store, ownerType) {
    const storeName = await ownerType
    if (!storeName) return null

    const response = await $store.dispatch(`${storeName}/findAll`, { noCommit: true })
    return response.data || null
  },

  async updateHistory($store, id) {
    const history = {
      id,
      finishedAt: new Date(),
    }

    $store.dispatch('bpHistories/updateHistory', history).catch((err) => console.error(err))
  },
}
