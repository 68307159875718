import axios from 'axios'
import _ from 'lodash'
import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IMainDesktopState, IMainDesktopSetting } from '../types/MainDesktopType'
import Vue from 'vue'

const apiUrl = 'main_desktop'

export const initState: IMainDesktopState = {
  settings: [],
}

export const state: IMainDesktopState = Object.assign({}, _.cloneDeep(initState))

export const mutations: MutationTree<IMainDesktopState> = {
  setSettings(state: IMainDesktopState, payload: Array<IMainDesktopSetting>) {
    state.settings = payload
  },
  addSetting(state: IMainDesktopState, payload: IMainDesktopSetting) {
    state.settings.push(payload)
  },
  deleteSetting(state: IMainDesktopState, index: number) {
    state.settings.splice(index, 1)
  },
  setSetting(state: IMainDesktopState, payload: any) {
    Vue.set(state.settings, payload.index, payload.setting)
  },
  resetState(state: IMainDesktopState) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const getters: GetterTree<IMainDesktopState, any> = {
  settings(state: IMainDesktopState): Array<IMainDesktopSetting> {
    return state.settings
  },
}

export const actions: ActionTree<IMainDesktopState, any> = {
  // Logs in the current user.
  async findAll({ commit }, pyload) {
    return axios
      .get(`/${apiUrl}`, pyload)
      .then((response) => {
        if (response.status === 200 && !pyload.noCommit) {
          commit('setSettings', response.data)
        }

        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async update({ commit }, pyload: object) {
    return axios
      .post(`/${apiUrl}/update_all`, pyload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  resetState({ commit }) {
    commit('resetState')
  },
}
